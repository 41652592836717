import React from 'react';
import {Link} from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import profile from '../../content/assets/profile.png';

import './index.scss';
export default function Profile({data, location}) {
  console.log(profile);
  return (
    <div className="about">
      <Layout
        location={location}
        title={'Uber Eats配達員からエンジニアになった男のブログ'}
      >
        <SEO title="Profile" />
        <article>
          <header className="article-title">Profile</header>
          <div className="w__about">
            <div className="image">
              <img src={`${profile}`} />
            </div>
            <h2>Basic</h2>
            <div className="w__content">
              <h3>Name</h3>
              <ul>
                <li>Reo Ishiyama</li>
              </ul>
              <h3>Birthday</h3>
              <ul>
                <li>1997/7/27</li>
              </ul>
              <h3>Email</h3>
              <ul>
                <li>nareto1125@gmail.com</li>
              </ul>
              <h3>Occupation</h3>
              <ul>
                <li>Fullstack Web developer </li>
                <ul>
                  <li>Work at Money Forward, Inc.</li>
                  <ul>
                    <li>developing 「マネーフォワード クラウド確定申告」</li>
                    <li>leading frontend design/architecture</li>
                  </ul>
                </ul>
                <ul>
                  <li>Chatwork Co., Ltd. 2020/08 ~ 2021/10</li>
                </ul>
                <ul>
                  <li>Gemcook, Inc. 2018/08 ~ 2020/06</li>
                </ul>
              </ul>
              <h3>OSS</h3>
              <ul>
                <li>Blitz.js L1 Maintainer</li>
              </ul>
            </div>
            <h2>Skills</h2>
            <div className="w__content">
              <h3>Languages/Libraries</h3>
              <ul>
                <li>TypeScript</li>
                <li>Ruby</li>
                <li>Next.js</li>
                <li>React</li>
                <li>React Native</li>
                <li>GpaphQL</li>
                <li>Apollo</li>
              </ul>
              <h3>Development Methodology</h3>
              <ul>
                <li>Agile</li>
                <ul>
                  <li>DDD</li>
                </ul>
                <li>Waterfall</li>
              </ul>
              <h3>UI Design</h3>
              <ul>
                <li>Sketch</li>
                <li>Figma</li>
                <li>InVision</li>
                <li>Zeplin</li>
              </ul>
              <h3>Certificates</h3>
              <ul>
                <li>AWS Certified Solutions Architect - Associate</li>
              </ul>
              <h3>Others</h3>
              <ul>
                <li>English (conversation level)</li>
              </ul>
            </div>
            <h2>Project Histories</h2>
            <div className="w__content">
              <ul className="projects">
                <li>安全確認サービス</li>
                <li>機材管理システム</li>
                <li>フォント販売Webサービス</li>
                <li>魚のオークションサービス</li>
                <li>選挙管理Webサービス</li>
                <li>モバイルオーダーシステム</li>
              </ul>
            </div>
          </div>
          <div className="link">
            <Link to="/">← BACK TO TOP</Link>
          </div>
          <footer className="main-footer">
            <Bio />
          </footer>
        </article>
      </Layout>
    </div>
  );
}
